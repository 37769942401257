<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>RouteList</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">RouteList</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <!-- Default box -->
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="saveItem" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{ name: 'RouteList' }"
                ><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="route_name" class="control-label">Name</label>
              <input
                type="text"
                id="route_name"
                name="route_name"
                v-model="item.route_name"
                v-validate="'required'"
                class="form-control"
                :class="{ 'has-error': errors.has('route_name') }"
                placeholder="Route name"
              />
              <div class="help text-danger" v-show="errors.has('route_name')">
                {{ errors.first("route_name") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="path_url" class="control-label">Path URL</label>
              <input
                type="text"
                id="path_url"
                name="path_url"
                v-model="item.path_url"
                v-validate="'required'"
                class="form-control"
                :class="{ 'has-error': errors.has('path_url') }"
                placeholder="path url"
              />
              <div class="help text-danger" v-show="errors.has('path_url')">
                {{ errors.first("path_url") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="route_controller" class="control-label"
                >Route Controller</label
              >
              <input
                type="text"
                id="route_controller"
                name="route_controller"
                v-model="item.route_controller"
                v-validate="'required'"
                class="form-control"
                :class="{ 'has-error': errors.has('route_controller') }"
                placeholder="Route controller"
              />
              <div
                class="help text-danger"
                v-show="errors.has('route_controller')"
              >
                {{ errors.first("route_controller") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="route_model" class="control-label">Route Model</label>
              <input
                type="text"
                id="route_model"
                name="route_model"
                v-model="item.route_model"
                v-validate="'required'"
                class="form-control"
                :class="{ 'has-error': errors.has('route_model') }"
                placeholder="Route model"
              />
              <div class="help text-danger" v-show="errors.has('route_model')">
                {{ errors.first("route_model") }}
              </div>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button
              type="submit"
              v-if="is_edit === false"
              class="btn btn-primary"
            >
              Submit
            </button>
            <button
              type="submit"
              v-if="is_edit === true"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { RouteListAPI } from "@/services/api";
export default {
  name: "RouteListForm",
  data: () => ({
    params: "",
    item: {},
    is_edit: false,
    is_loading: false,
  }),
  methods: {
    saveItem: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.is_edit) {
            RouteListAPI.update(this.params.id, this.item)
              .then((response) => {
                this.$router.push({ name: "RouteList" });
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          } else {
            RouteListAPI.store(this.item)
              .then((response) => {
                this.$router.push({ name: "RouteList" });
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          }
        }
      });
    },
    itemDetail: function (id) {
      return new Promise((resolve) => {
        let _this = this;
        RouteListAPI.show(id)
          .then((response) => {
            _this.item = response;
            resolve("resolved");
          })
          .catch((error) => {
            resolve("rejected");
          });
      });
    },
    async getInitialData() {
      let _this = this;
      _this.params = _this.$route.params;
      if (!_.isEmpty(_this.params) && _this.params.id !== undefined) {
        _this.is_edit = true;
      }
      _this.is_loading = true;
      if (_this.is_edit === true) {
        await this.itemDetail(_this.params.id);
      }
      _this.is_loading = false;
    },
  },
  mounted: function () {
    let _this = this;
    _this.getInitialData();
  },
};
</script>
